import { HydrateOption } from '@core/enums';
import Flicking, { ALIGN, DIRECTION, } from '@egjs/react-flicking';
import withWidget from '@hoc/withWidget';
import NextButton from '@stories/Components/Buttons/NextButton/NextButton';
import PrevButton from '@stories/Components/Buttons/PrevButton/PrevButton';
import Image from '@stories/Components/Media/Image/Image';
import React, { useState } from 'react';
import FeaturedCard from './FeaturedCard/FeaturedCard';
import S from './FeaturedFullWidth.styles';
import { ThemeWrapper } from '@helpers/theme';
const FeaturedFullWidth = ({ bgImage, theme, items = [] }) => {
    const refFlicking = React.useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showControls, setShowControls] = React.useState(false);
    return (React.createElement(ThemeWrapper, { "data-theme": theme },
        React.createElement(S.Container, null,
            React.createElement(S.ImageWrapperInner, null,
                React.createElement(Image, { ...bgImage, objectFit: "cover", role: "presentation" }),
                React.createElement(S.ImageOverlay, null)),
            React.createElement(S.Inner, null,
                React.createElement(S.ItemList, null,
                    React.createElement(Flicking, { ref: refFlicking, align: ALIGN.PREV, bound: true, onAfterResize: (event) => {
                            // Move to first item after resizing to fix misalignment.
                            event.currentTarget.moveTo(0);
                            const visiblePanels = getVisiblePanels(event.currentTarget);
                            // Hide controls when all panels are visible.
                            setShowControls(!(visiblePanels.length === items.length));
                        }, onChanged: (event) => {
                            // Auto-focus the new slide if the focus was already inside the carousel.
                            if (event.currentTarget.element.contains(document.activeElement)) {
                                event.panel.element.focus();
                            }
                        }, onWillChange: (event) => {
                            if (event.index > items.length - 1) {
                                setActiveIndex(event.index - items.length);
                            }
                            else {
                                setActiveIndex(event.index);
                            }
                        }, onMoveStart: (event) => {
                            const visiblePanels = getVisiblePanels(event.currentTarget);
                            // Avoid swiping when all panels are visible.
                            if (visiblePanels.length === items.length) {
                                event.stop();
                            }
                        }, onReady: (event) => {
                            const visiblePanels = getVisiblePanels(event.currentTarget);
                            // Hide controls when all panels are visible.
                            setShowControls(!(visiblePanels.length === items.length));
                        } }, items.map((item, i) => (React.createElement(S.Item, { key: i },
                        React.createElement(FeaturedCard, { ...item, theme: theme })))))),
                showControls && (React.createElement(S.Controls, null,
                    React.createElement(S.DotHolder, { "data-hidden": items.length > 5 ? '1' : '0' }, items.map((item, index) => (React.createElement(S.CarouselDot, { key: item.title, disabled: activeIndex === index, onClick: (event) => {
                            event.preventDefault();
                            moveTo(index);
                        } })))),
                    React.createElement(PrevButton, { disabled: activeIndex === 0, themeOption: "primaryAlt", onClick: (event) => {
                            event.preventDefault();
                            refFlicking.current?.prev();
                        } }),
                    React.createElement(NextButton, { disabled: activeIndex === items.length - 1, themeOption: "primaryAlt", onClick: (event) => {
                            event.preventDefault();
                            refFlicking.current?.next();
                        } })))))));
    function moveTo(index) {
        const actualIndex = refFlicking.current?.index ?? 0;
        // Adjust index to account for cloned items.
        if (actualIndex > items.length - 1) {
            index += items.length;
        }
        const direction = index < actualIndex ? DIRECTION.PREV : DIRECTION.NEXT;
        refFlicking.current?.moveTo(index, undefined, direction);
    }
};
export default withWidget(FeaturedFullWidth, 'FeaturedFullWidth', {
    hydrate: HydrateOption.InView,
});
function getVisiblePanels(instance) {
    return instance.panels.filter((panel) => panel.visibleRatio >= 0.9);
}
