import PluginCss from '!raw-loader!@egjs/react-flicking/dist/flicking.css';
import brand from '@helpers/brand';
import { Grid } from '@helpers/grid';
import { Device, from, until } from '@helpers/media';
import { ThemeSwitch } from '@helpers/theme';
import NextButtonStyles from '@stories/Components/Buttons/NextButton/NextButton.styles';
import { DocumentCardStyles } from '@stories/Components/Cards/DocumentCard/DocumentCard.styles';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import styled from 'styled-components';
const Container = styled.div `
  overflow: hidden;
  position: relative;

  @media ${from(Device.Tablet)} {
    padding: 60px 0 40px;
  }
`;
const Inner = styled(Grid.Default) `
  position: relative;
  overflow-x: hidden;
  grid-column: span 12;

  ${HeadingStyles.Heading} {
    grid-column: span 12;
    text-align: center;
  }

  @media ${until(Device.Tablet)} {
    display: block;
  }
`;
const ImageWrapperInner = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
const ImageOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: ${brand.black};
  opacity: 0.8;
`;
const Title = styled.h2 `
  ${HeadingStyles.H3Styles}
  color: ${brand.white};
`;
const Intro = styled.p `
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 30px;
  color: ${brand.white};

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    line-height: 28px;
    max-width: 670px;
    margin: 0 auto 80px;
  }
`;
const ItemList = styled.div `
  ${PluginCss};

  display: flex;
  width: 100%;

  .flicking-viewport {
    overflow: visible;
    width: 100%;
  }

  @media ${from(Device.Tablet)} {
    grid-column: span 12;
  }
`;
const Item = styled.div `
  --itemWidth: 100%;

  display: block;
  flex: 1 0 var(--itemWidth);
  margin: 0px 100px;

  ${DocumentCardStyles.Card} {
    height: 100%;
  }
`;
const CtaWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 22px 0 0;

  @media ${from(Device.Tablet)} {
    justify-content: center;
    margin: 64px 0 0;
  }
`;
const Controls = styled.div `
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 0;

  @media ${from(Device.TabletLarge)} {
    margin: 40px 0 0;
    grid-column: 2 / span 4;
    flex-wrap: wrap;
    transform: translateY(-220px);
  }

  ${NextButtonStyles.Button} {
    margin-left: 12px;
  }
`;
const CarouselDot = styled.button.attrs({ type: 'button' }) `
  display: flex;
  flex: 1 0 auto;
  border: none;
  border-radius: 0;
  height: 5px;
  padding: 0;
  background-color: ${brand.grey.grey89};
  transform-origin: center bottom;
  transition: background-color 0.15s ease-out, transform 0.15s ease-out;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    transform: scaleY(2);
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    background-color: var(--theme);
  }

  &[disabled] {
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })}
    background-color: var(--theme);
  }
`;
const DotHolder = styled.div `
  display: flex;
  flex: 1 0 auto;
  padding-right: 20px;
  justify-content: center;
  gap: 6px;

  @media ${until(Device.TabletLarge)} {
    &[data-hidden='1'] {
      display: none;
    }
  }

  @media ${from(Device.TabletLarge)} {
    gap: 10px;
    grid-column: 2;
    flex: 1 0 100%;
    padding: 0;
    padding-bottom: 30px;
  }
`;
const Base = styled.div `
  padding: 30px 0;
  text-align: center;
`;
export default {
    Base,
    CarouselDot,
    Container,
    Controls,
    CtaWrapper,
    DotHolder,
    ImageOverlay,
    ImageWrapperInner,
    Inner,
    Intro,
    Item,
    ItemList,
    Title,
};
